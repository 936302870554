body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

.photo-album {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}

.timeline {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.timeline-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.timeline-item.active {
  background-color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.photos-container {
  width: 100%;
  height: 100%;
}

.photo-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  scroll-snap-align: start;
  overflow: hidden;
}

.photo-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-content {
  position: absolute;
  bottom: 40px;
  left: 40px;
  right: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
}

.nav-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

@media (max-width: 768px) {
  .text-content {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: none;
    border-radius: 0;
    padding: 20px;
    box-sizing: border-box;
    display: fixed;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: calc(100% - 35vh);
  }

  .date {
    font-size: 16px;
  }

  .description {
    font-size: 14px;
  }

  .nav-button {
    display: none;
  }
}