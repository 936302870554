body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden;
  font-family: Arial, sans-serif;
}

.photo-album {
  position: relative;
  height: 100vh;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
}

.timeline {
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
}

.timeline-item {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.timeline-item.active {
  background-color: white;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

.photos-container {
  height: 100%;
}

.photo-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  scroll-snap-align: start;
  padding: 20px;
  box-sizing: border-box;
}

.photo {
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.text-content {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  box-sizing: border-box;
}

.date {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 16px;
  line-height: 1.5;
}

.nav-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 10;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.4);
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

@media (min-width: 769px) {
  .photo-wrapper {
    flex-direction: row;
    padding: 0 5%;
  }

  .photo {
    max-width: 60%;
    max-height: 80vh;
  }

  .text-content {
    position: absolute;
    width: 30%;
    margin-top: 0;
  }

  .text-left .text-content {
    left: 5%;
  }

  .text-right .text-content {
    right: 5%;
  }
}

@media (max-width: 768px) {
  .photo-wrapper {
    height: auto;
    min-height: 100vh;
  }

  .photo {
    max-height: 50vh;
  }

  .text-content {
    opacity: 1;
  }
}