body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  }
  
  .photo-album {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  .photo-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    box-sizing: border-box;
  }

  .photo-container video {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  
  .photo-container {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .photo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .text-content {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  
  .date {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .description {
    font-size: 14px;
    line-height: 1.4;
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .photo-container {
      height: 75vh;
      padding-bottom: 20vh;
    }
  
    .text-content {
      font-size: 14px;
    }
  
    .date {
      font-size: 14px;
    }
  
    .description {
      font-size: 12px;
    }
  }